import React, { useEffect, ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import numeral from 'numeral';
import { Card } from '../../components';
import styles from './styles.module.scss';
import { P } from '../../components/Typography';
import Toggler from '../../components/Toggler';
import Tooltip from '../../components/Tooltip';
import actionTypes from '../../store/tokenGeyser/actionTypes';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Stats from './Stats';
import { SelectOption } from '../../pages/Main';
import { RootStoreState } from '../../types/store';
import { HandleAPY } from '../../utils/calculations';
import { SYMBOL } from '../../appConstants';

const togglerOptions = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Stats', value: 'stats' },
];

type Props = {
  selectOption: SelectOption;
  balance: string;
  balanceDeposit: string;
  graphValues: any;
  contractValues: any;
};

const Wallet = ({ selectOption, balance, balanceDeposit, graphValues, contractValues }: Props) => {

  const APY = HandleAPY(graphValues,contractValues);

  const dispatch = useDispatch();

  const [depositAmount, setDepositAmount] = useState<string>('');
  const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  const [selectedPart, setSelectedPart] = useState<number>(0);

  const pairs = useSelector((state: RootStoreState) => state.tokenGeyser.pairs);
  const selectedPairs = useSelector((state: RootStoreState) => state.tokenGeyser.selectedPairs);
  const rewardMultiplier = (!+pairs[selectedPairs]?.getMultiplier) ? 1 : +pairs[selectedPairs]?.getMultiplier; // 8
  const accruedRewards = +pairs[selectedPairs]?.accruedRewards; // 9
  const smallCardsDepositData = [
    {
      id: 1,
      title: 'APY:',
      content: `${numeral(APY || 0).format('0,0.0%')}`,
      info:
        'APY is estimated optimistically and does not account for LP token value loss or gain.',
    },
    {
      id: 2,
      title: 'Reward Multiplier:',
      content: `${numeral(rewardMultiplier || 0).format('0,0.00')}x`,
      info:
        'Deposit liquidity tokens for 60 days to achieve a 2x reward multiplier.',
    },
    {
      id: 3,
      title: 'Accrued Rewards:',
      content: `${numeral(accruedRewards || 0).format('0,0.00')} ${SYMBOL}`,
      info:
        'Total rewards you have accrued till date. Larger your deposit and for longer, higher the accrual rate.',
    },
  ];

  const depositHandler = () => {
    dispatch({
      type: actionTypes.TOKEN_GEYSER_DEPOSIT,
      payload: { depositAmount, geyser: selectOption.geyser },
    });
  };

  const withdrawHandler = () => {
    dispatch({
      type: actionTypes.TOKEN_GEYSER_WITHDRAW,
      payload: { withdrawAmount, geyser: selectOption.geyser },
    });
  };

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'deposit') {
      setDepositAmount(e.currentTarget.value);
    }
    if (e.currentTarget.name === 'withdraw') {
      setWithdrawAmount(e.currentTarget.value);
    }
  };

  const switchPagesHandler = (num: number) => {
    setSelectedPart(num);
    setDepositAmount('');
    setWithdrawAmount('');
  };

  const clear = () => {
    setDepositAmount('');
    setWithdrawAmount('');
  };

  return (
    <Card className={styles.walletWrapper}>
      <div className={styles.smallCardsWrapper}>
        {smallCardsDepositData.map((card) => (
          <Card key={card.title} className={styles.smallCard}>
            <Tooltip
              name={card.id}
              target={<i className="icon-attention-alt" />}
              content={card.info}
            />
            <P>{card.title}</P>
            <P bold>{card.content}</P>
          </Card>
        ))}
      </div>
      <Toggler
        className={styles.toggler}
        options={togglerOptions}
        onToggle={switchPagesHandler}
        selected={selectedPart}
      />
      {selectedPart === 0 && (
        <Deposit
          nameInput="deposit"
          depositAmount={depositAmount}
          setDepositAmount={inputHandler}
          depositHandler={depositHandler}
          selectOption={selectOption}
          balance={balance}
          clear={clear}
          graphValues={graphValues}
          contractValues={contractValues} 
        />
      )}
      {selectedPart === 1 && (
        <Withdraw
          nameInput="withdraw"
          withdrawAmount={withdrawAmount}
          setWithdrawAmount={inputHandler}
          withdrawHandler={withdrawHandler}
          selectOption={selectOption}
          balance={balanceDeposit}
          clear={clear}
        />
      )}
      {selectedPart === 2 && (
        <Stats 
          selectOption={selectOption}
          graphValues={graphValues}
          contractValues={contractValues} 
        />
      )}
    </Card>
  );
};

export default Wallet;
