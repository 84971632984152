import { takeLeading, call, put } from 'redux-saga/effects';
import { getWeb3 } from '../../../utils/metamask';
import actionTypes, {DepositTokenGeyserAction as Action} from '../actionTypes';
import abiTokenGeyser from '../../../appConstants/contracts/abiTokenGeyser.json';
import apiActions from '../../api/actions';


function* withdrawToken({ type, payload}: Action ) {
  const { geyser, withdrawAmount } = payload;
  const web3 = yield call(getWeb3);
  const accounts = yield call(web3.eth.getAccounts);
  const geyserContract = new web3.eth.Contract(abiTokenGeyser, geyser);
  const myAddress = accounts[0];
  try {
    yield put(apiActions.request(type));
    yield call(geyserContract.methods
      .unstake( String((+withdrawAmount * 10 ** 18).toFixed(0)), '0x00').send, {
      from: myAddress,
      value: 0,
    });
    yield put(apiActions.success(type));
    yield put({type: actionTypes.GET_PAIRS});
  } catch(err) {
    yield put(apiActions.error(type, err));
  }
}

export default function* listener(): Iterable<any> {
  yield takeLeading(actionTypes.TOKEN_GEYSER_WITHDRAW, withdrawToken);
}