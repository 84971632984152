import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import numeral from 'numeral';
import styles from './styles.module.scss';
import { Card } from '../../components';
import { P } from '../../components/Typography';
import actionTypes from '../../store/tokenGeyser/actionTypes';
import { SelectOption } from '../../pages/Main';
import { RootStoreState } from '../../types/store';
import { PAIR_QUERY } from '../../graphql/queries';
import { graphConstants } from '../../graphql/graphConstants';
import { SYMBOL } from '../../appConstants';
import { HandleStats } from '../../utils/calculations';

type Props = {
  selectOption: SelectOption;
  graphValues: any;
  contractValues: any;
};

const Stats = ({ selectOption, graphValues, contractValues }: Props) => {
  const pairs = useSelector((state: RootStoreState) => state.tokenGeyser.pairs);
  const selectedPairs = useSelector((state: RootStoreState) => state.tokenGeyser.selectedPairs);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch({ type: actionTypes.GET_STATS_DATA, payload: selectOption.label });
  }, [selectOption]);

  const stats = HandleStats(graphValues, contractValues);

  const smallCardsStatsData = [
    {
      title: 'Total Rewards:',
      content: `${numeral(stats.totalRewards || 0).format('0,0.00')} ${SYMBOL}`,
    },
    {
      title: 'Total Deposits:',
      content: `${numeral(stats.totalDeposits || 0).format('0,0.00')} USD | ${numeral(stats.totalStaked || 0).format('0.000')} LP`,
    },
    {
      title: 'Locked Rewards:',
      content: `${numeral(stats.totalLocked || 0).format('0,0.00')} ${SYMBOL}`,
    },
    {
      title: 'Unlocked Rewards:',
      content: `${numeral(stats.totalUnlocked || 0).format('0,0.00')} ${SYMBOL}`,
    },
    {
      title: 'Reward unlock rate:',
      content: `${numeral(stats.rewardUnlockRateMonth || 0).format('0,0.00')} ${SYMBOL} / month`,
    },
    {
      title: 'Time Remaining:',
      content: `${numeral(stats.timeRemainingDays || 0).format('0,0.00')} Days`,
    },
  ];

  return (
    <div className={styles.statsCardWrapper}>
      {/* <Preloader isLoading={isLoaded !== 'SUCCESS'}> */}
      {smallCardsStatsData.map((card) => (
        <Card key={card.title} className={styles.statsCard}>
          <P>{card.title}</P>
          <P bold>{card.content}</P>
        </Card>
      ))}
    </div>
  );
};

export default Stats;
