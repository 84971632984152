import abiZCN from './contracts/abiZCN.json';
import abiWETH from './contracts/abiWETH.json';

export const infura = `wss://${process.env.REACT_APP_NETWORK}.infura.io/ws/v3/${process.env.REACT_APP_INFURAKEY}`

export const addresses = { 
    ZCN: process.env.REACT_APP_DISTRIBUTIONTOKENADDRESS,
    ETH: process.env.REACT_APP_WETHTOKENADDRESS,
    uniswap_ETH: process.env.REACT_APP_STAKINGTOKENADDRESS,
    geyser_ETH: process.env.REACT_APP_GEYSERADDRESS
};

export default [
  {
    token1: {
      address: addresses.ZCN,
      abi: abiZCN,
    },
    token2: {
      address: addresses.ETH,
      abi: abiWETH,
    },
    uniswap: addresses.uniswap_ETH,
    geyser: addresses.geyser_ETH,
  },
];
