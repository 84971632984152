import { fork } from 'redux-saga/effects';

import web3Saga from './web3/saga';
import tokenGeyser from './tokenGeyser/saga';


export default function* rootSaga() {
  yield fork(web3Saga);
  yield fork(tokenGeyser);
}
