/* ts-ignore */
import { takeLeading, call, all, put } from 'redux-saga/effects';
import { getWeb3 } from '../../../utils/metamask';
import contractConstants from '../../../appConstants/contractConstants';
import apiActions from '../../api/actions';
import abiPair from '../../../appConstants/contracts/abiPair.json';
import abiTokenGeyser from '../../../appConstants/contracts/abiTokenGeyser.json';
import { TokenPairConstant } from '../../../types/contracts';
import actionTypes from '../actionTypes';


function* handleTime(count: number, tokenGeyser: any, myAddress: string) {
  let time = 0;
  let unlockSharesRate = 0;
  let remainingLockedShares = 0;
  for (let i = 0; i < count; i += 1) {
    const unlockSchedules = yield call(tokenGeyser.methods
      .unlockSchedules(i).call, {
      from: myAddress,
      value: 0,
    });
    unlockSharesRate += (unlockSchedules[0] - unlockSchedules[1]) / (unlockSchedules[3] - unlockSchedules[2]);
    remainingLockedShares += unlockSchedules[0] - unlockSchedules[1];
  }
  time = remainingLockedShares / unlockSharesRate;
  return time;
}

// eslint-disable-next-line consistent-return
function* getOneTokenInfo(token: TokenPairConstant, index: number, type: string) {
  const web3 = yield call(getWeb3);
  const accounts = yield call(web3.eth.getAccounts);
  const myAddress = accounts[0];
  const token1 = new web3.eth.Contract(token.token1.abi, token.token1.address);
  const token2 = new web3.eth.Contract(token.token2.abi, token.token2.address);
  const tokenPair = new web3.eth.Contract(abiPair, token.uniswap);
  const tokenGeyser = new web3.eth.Contract(abiTokenGeyser, token.geyser);

  try {
    yield put(apiActions.request(type));

    const accruedRewards = yield call(tokenGeyser.methods
      .getAccruedRewards().call, {
      from: myAddress,
      value: 0,
    });
    const getMultiplier = yield call(tokenGeyser.methods
      .getMultiplier().call, {
      from: myAddress,
      value: 0,
    });
    const totalStaked = yield call(tokenGeyser.methods
      .totalStaked().call, {
      from: myAddress,
      value: 0,
    });
    const unlockScheduleCount = yield call(tokenGeyser.methods
      .unlockScheduleCount().call, {
      from: myAddress,
      value: 0,
    });

    const time = yield call(handleTime, unlockScheduleCount, tokenGeyser, myAddress);

    const totalSupply = yield call(tokenPair.methods
      .totalSupply().call, {
      from: myAddress,
      value: 0,
    });
    const LPTokensUsers = yield call(tokenGeyser.methods
      .totalStakedFor(myAddress).call, {
      from: myAddress,
      value: 0,
    });
    const totalUnlocked = yield call(tokenGeyser.methods
      .totalUnlocked().call, {
      from: myAddress,
      value: 0,
    });
    const totalLocked = yield call(tokenGeyser.methods
      .totalLocked().call, {
      from: myAddress,
      value: 0,
    });
    const token1Name = yield call(token1.methods
      .name().call, {
      from: myAddress,
      value: 0,
    });
    const token1Decimals = yield call(token1.methods
      .decimals().call, {
      from: myAddress,
      value: 0,
    });
    const token2Name = yield call(token2.methods
      .name().call, {
      from: myAddress,
      value: 0,
    });
    const token2Decimals = yield call(token2.methods
      .decimals().call, {
      from: myAddress,
      value: 0,
    });

    const tokenPairBalance = yield call(tokenPair.methods
      .balanceOf(myAddress).call, {
      from: myAddress,
      value: 0,
    });
  
    const pairInfo = {
      id: token.geyser,
      token1: {
        name: token1Name,
        decimals: token1Decimals,
        address: token.token1.address,
      },
      token2: {
        name: token2Name,
        decimals: token2Decimals,
        address: token.token2.address,
      },
      accruedRewards: String(parseFloat(accruedRewards) / 10**token1Decimals),
      getMultiplier,
      unlockScheduleCount,
      totalLocked: String(parseFloat(totalLocked) / 10**token1Decimals),
      LPTokensUsers: String(parseFloat(LPTokensUsers) / 10**18),
      tokenPairBalance: web3.utils.fromWei(tokenPairBalance, 'ether'),
      totalUnlocked: String(parseFloat(totalUnlocked) / 10**token1Decimals),
      totalSupply: web3.utils.fromWei(totalSupply, 'ether'),
      totalStaked: String(parseFloat(totalStaked) / 10**18),
      time,
    };
    yield put(apiActions.success(type, pairInfo));
    return pairInfo;
  } catch (e) {
    return console.log('error', e);
  }
}


// @ts-ignore
function* getAllTokensInfo(tokens: Array<TokenPairConstant>, { type }) {
  try {
    const allPairs = yield all(tokens.map((token: TokenPairConstant, index) => call(getOneTokenInfo, token, index, type)));
    localStorage.setItem('tokens', JSON.stringify(allPairs));
  } catch (e) {
    console.log(e);
  }
}


export default function* listener(): Iterable<any> {
  // @ts-ignore
  yield takeLeading(actionTypes.GET_PAIRS, getAllTokensInfo, contractConstants);
}
