import { useSelector } from 'react-redux';
import { RootStoreState } from '../types/store';
import { infura, addresses } from '../appConstants';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { PAIR_QUERY, ETH_PRICE } from '../graphql/queries';
import { graphConstants } from '../graphql/graphConstants';
import Web3 from 'web3';
import * as abiGeyser from '../appConstants/contracts/abiTokenGeyser.json';

const address = addresses.geyser_ETH;
const abi = (abiGeyser as any).default;
const provider = infura;    
const web3 = new Web3(new Web3.providers.WebsocketProvider(provider));
const contract = new web3.eth.Contract(abi,address);
const DECIMALS = {ZCN : 10**10, Default: 10**18};

export const globalInit = () => async (graphData : any) => {
  const contractValues = await fallBackInit();
  const graphValues = await graphInit(graphData);
  return {graphValues, contractValues};
}

const fallBackInit = async () => { 
  let schedules : any[] = [];
  const reducer = (accum:number, current:number) => accum + current;
  const totalStakedDecimals = await contract.methods.totalStaked().call();
  const totalLockedDecimals = await contract.methods.totalLocked().call();
  const scheduleCount = await contract.methods.unlockScheduleCount().call();
  const totalStaked:number = +totalStakedDecimals / DECIMALS.Default;
  const totalLocked:number = +totalLockedDecimals / DECIMALS.ZCN;
  for(let i=0; i<scheduleCount; i+=1){schedules.push(await contract.methods.unlockSchedules(i).call())};
  const rates:number[] = schedules.map(schedule => (+schedule[0] - +schedule[1]) / (+schedule[3] - +schedule[2]));
  const unlockSharesRate:number = rates.filter(rate => Number.isFinite(rate)).reduce(reducer);
  const remainingShares:number = schedules.map(schedule => +schedule[0] - +schedule[1]).reduce(reducer);  
  const releasedShares:number = schedules.map(schedule => +schedule[1]).reduce(reducer);
  const sharesPerReward:number = remainingShares / totalLocked;
  const timeRemainingSec:number = remainingShares / unlockSharesRate;
  const totalUnlocked:number = releasedShares / sharesPerReward;
  const totalRewards:number = totalLocked + totalUnlocked;
  const rewardUnlockRate:number = totalLocked / timeRemainingSec; 
  const contractValues:any = {totalStaked, totalLocked, totalUnlocked, totalRewards, rewardUnlockRate, timeRemainingSec};
  const validValues  = Object.keys(contractValues).every(keys => contractValues[keys])
  return validValues ? contractValues : 0;
};

const graphInit = async (graphData: any) => {
  const graphDataKeys = [ 'pairData', 'ethData' ]; 
  const graphDataSuccess = Object.keys(graphData).every(key => graphDataKeys.includes(key)); 
  if(!graphDataSuccess){console.error( 'graphData signature is not valid or not a valid Object.' );}
  const ethUsdFx = +graphData?.ethData?.bundle?.ethPrice;
  const zcnEthFx = +graphData?.pairData?.pair?.token0?.derivedETH;
  const liquidityPair = +graphData?.pairData?.pair?.reserveUSD;
  const UNILP = +graphData?.pairData?.pair?.totalSupply;
  const LPValue = liquidityPair / UNILP; // 3
  const ZCNPrice = ethUsdFx * zcnEthFx;
  const graphValues:any = {LPValue, liquidityPair, ZCNPrice, ethUsdFx, zcnEthFx, UNILP}; 
  return graphValues;
};

export const RewardsClaimed = (withdrawAmount: number, depositBalance: number) : number => {
  const pairs = useSelector(((state: RootStoreState) => state.tokenGeyser.pairs));
  const selectedPairs = useSelector(((state: RootStoreState) => state.tokenGeyser.selectedPairs));
  if (!withdrawAmount) return 0;
  return withdrawAmount * +pairs[selectedPairs]?.accruedRewards / depositBalance;
};


export const HandleMonthly = (depositAmount: number, contractValues: any) : number => {
  const totalStaked : number = +contractValues?.totalStaked; 
  const rewardUnlockRate : number = +contractValues?.rewardUnlockRate; 
  const pairs = useSelector(((state: RootStoreState) => state.tokenGeyser.pairs));
  const selectedPairs = useSelector(((state: RootStoreState) => state.tokenGeyser.selectedPairs));
  const selectedPair = pairs[selectedPairs];
  const defaultBonusMultiple = ( (1.5*60) + (2*305) ) / 365; // 1.91781
  const depositRatio = (+depositAmount || +selectedPair?.LPTokensUsers) / (+selectedPair?.totalStaked || totalStaked);
  const result = depositRatio * rewardUnlockRate * defaultBonusMultiple * 30 * 86400;
  return Number.isNaN(result) ? 0 : result;
};


export const HandleAPY = (graphValues: any, contractValues: any) : number => {
  const GEYLP = contractValues?.totalStaked;
  const rewardUnlockRate = contractValues?.rewardUnlockRate; 
  const LPValue = graphValues?.LPValue;
  const ZCNPrice = graphValues?.ZCNPrice;
  const totalDeposits = GEYLP * LPValue; // 4 
  const annualSeconds = 365 * 86400;
  const compoundSeconds = 1; // set to 1 if no compounding. 
  const defaultBonusMultiple = ( (1.5*60) + (2*305) ) / 365; // 1.91781
  const APR = annualSeconds * defaultBonusMultiple * rewardUnlockRate * ZCNPrice / totalDeposits;
  const APY = (1 + APR/compoundSeconds)**compoundSeconds -1;
  return Number.isFinite(APY) ? APY : 0;
};


export const HandleStats = (graphValues :any, contractValues: any ) : any => {
  const	totalDeposits : number = contractValues?.totalStaked * graphValues?.LPValue;
  const rewardUnlockRateMonth : number = contractValues?.rewardUnlockRate * 30 * 86400;
  const timeRemainingDays : number = contractValues?.timeRemainingSec / 86400;
  const statsData = {...contractValues, totalDeposits, rewardUnlockRateMonth, timeRemainingDays};
  return statsData;  
};
