import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Header from '../../containers/Header';
import Wallet from '../../containers/Wallet';
import styles from './styles.module.scss';
import {RootStoreState} from '../../types/store';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { PAIR_QUERY, ETH_PRICE } from '../../graphql/queries';
import { graphConstants } from '../../graphql/graphConstants';
import actionTypes from '../../store/tokenGeyser/actionTypes';
import { globalInit } from '../../utils/calculations';

export type SelectOption = {
  label: string,
  value: string,
  balance:string,
  balanceDeposit:string,
  geyser: string,
  index: number,
};

const Main = () => {
  
  const [getPair, { data: pairData }] = useLazyQuery(PAIR_QUERY);
  const [getEth, { data: ethData }] = useLazyQuery(ETH_PRICE);
  const graphData = { pairData, ethData };

  useEffect(() => {
    getPair({variables: {id: graphConstants.pairAddress}});
    getEth();
  },[getPair, getEth]);

  const dispatch = useDispatch();
  const [selectOption, setSelectOption] = useState<SelectOption>({} as SelectOption);
  const ethAddress = useSelector((state: RootStoreState) => state.web3.address);
  const tokenPairs = useSelector(((state: RootStoreState) => state.tokenGeyser.pairs));

  const [graphValuesState, setGraphValuesState] = useState();
  const [contractValuesState, setContractValuesState] = useState(); 
  
  const setSelectOptionsStore = () => {
    if(tokenPairs && tokenPairs.length) {
      return tokenPairs.map((token, i) => (
        {
          index: i,
          label: `${token.token1.name}-${token.token2.name}`,
          value: `https://app.uniswap.org/#/add/${token.token1.address}/${token.token2.address}`,
          balance: token.tokenPairBalance,
          balanceDeposit: token.LPTokensUsers,
          geyser: token.id,
        }
      ));
    }
    return null;
  };

  useEffect(() => {
    if(graphData && !graphValuesState && !contractValuesState){
      globalInit();
      globalInit()(graphData).then(result => {
        const contractValues = result.contractValues;
        const graphValues = result.graphValues;
        if(graphValues !== graphValuesState) setGraphValuesState(graphValues); 
        if(contractValues !== contractValuesState) setContractValuesState(contractValues); 
      });
    }    
  },[graphData, contractValuesState])

  useEffect(() => {
    dispatch({type: actionTypes.GET_PAIRS});
  }, [ethAddress]);

  useEffect(() => {
    if (setSelectOptionsStore() !== null) {
      // @ts-ignore
      setSelectOption(setSelectOptionsStore()[0]);
    }
  }, [tokenPairs]);

  const handleSelected = (e: SelectOption) => {
    setSelectOption(e);
    dispatch({ type: 'SET_SELECTED_PAIRS', payload: e.index });
  };

  return (
    <div className={styles.main}>
      <Header
        ethAddress={ethAddress}
        selectOption={selectOption}
        setSelectOption={handleSelected}
        selectOptions={setSelectOptionsStore() as SelectOption[]}
      />
      <Wallet
        balance={selectOption.balance}
        balanceDeposit={selectOption.balanceDeposit}
        selectOption={selectOption}
        contractValues={contractValuesState}
        graphValues={graphValuesState}
      />
    </div>
  );
};

export default Main;
