import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { P } from '../../components/Typography';
import InputAmount from '../../components/InputAmount';
import { Button, Card, Spinner } from '../../components';
import { SelectOption } from '../../pages/Main';
import { RewardsClaimed } from '../../utils/calculations';
import { RootStoreState } from '../../types/store';
import { SYMBOL } from '../../appConstants';

type Props = {
  withdrawAmount: string | number;
  setWithdrawAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  withdrawHandler: () => void;
  clear: () => void;
  selectOption: SelectOption;
  balance: string;
  nameInput: string;
};

const Withdraw = ({
  withdrawAmount,
  setWithdrawAmount,
  withdrawHandler,
  clear,
  selectOption,
  balance,
  nameInput,
}: Props) => {
  const smallCardsWithdrawData = [
    { title: 'Amount to withdraw:', content: `${(+withdrawAmount || 0).toFixed(8)} ${
      selectOption.label || ''
    }-V2` },
    {
      title: 'Rewards claimed:',
      content: `${(RewardsClaimed(+withdrawAmount, +balance) || 0).toFixed(8)} ${SYMBOL}`,
    },
  ];
  const isDisable = !(withdrawAmount && +withdrawAmount > 0 && +balance >= +withdrawAmount);

  const depositStatus = useSelector(
    (state: RootStoreState) => state.ui.TOKEN_GEYSER_WITHDRAW,
  );
  const isLoader = depositStatus === 'REQUEST' ;

  const messageError = useSelector(
    (state: RootStoreState) => state.ui.message,
  );

  useEffect(() => {
    if(depositStatus === 'SUCCESS') {
      clear(); 
    }
  }, [depositStatus]);

  return (
    <>
      <div className={styles.inputWrapper}>
        <P size="sm">{`Deposited: ${(parseFloat(balance) || 0).toFixed(18)} (${
          selectOption.label || ''
        }-V2)`}</P>
        <InputAmount
          name={nameInput}
          value={withdrawAmount}
          onChange={setWithdrawAmount}
        />
      </div>
      <div className={styles.smallCardsWithDraw}>
        {smallCardsWithdrawData.map((card) => (
          <Card key={card.title} className={styles.withDrawCard}>
            <P>{card.title}</P>
            <P bold>{card.content}</P>
          </Card>
        ))}
      </div>
      {depositStatus === 'ERROR' && (
        <div className={styles.error}>
          {messageError}
        </div>
      )}
      {isLoader && (
        <div className={styles.containerSpinner}>
          <Spinner/>
        </div>
      )}
      <Button
        disabled={isDisable}
        loading={isLoader}
        name="Withdraw"
        className={styles.depositButton}
        onClick={withdrawHandler}
      />
    </>
  );
};

export default Withdraw;
