import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { P, H5 } from '../../components/Typography';
import { Button, Card, Spinner } from '../../components';
import Cube from '../../components/Cube';
import { SelectOption } from '../../pages/Main';
import Tooltip from '../../components/Tooltip';
import InputAmount from '../../components/InputAmount';
import { HandleMonthly } from '../../utils/calculations';
import { RootStoreState } from '../../types/store';
import { SYMBOL } from '../../appConstants';
import numeral from 'numeral';

type Props = {
  depositAmount: string;
  setDepositAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  depositHandler: () => void;
  clear: () => void;
  selectOption: SelectOption;
  balance: string;
  nameInput: string;
  graphValues : any;
  contractValues : any;
};

const Deposit = ({
  depositAmount,
  setDepositAmount,
  depositHandler,
  clear,
  selectOption,
  nameInput,
  balance,
  graphValues,
  contractValues,
}: Props) => {

  const pairs = useSelector(((state: RootStoreState) => state.tokenGeyser.pairs));
  const selectedPairs = useSelector(((state: RootStoreState) => state.tokenGeyser.selectedPairs));
  const selectedPair = pairs[selectedPairs];

  let monthlyRewards = HandleMonthly(+depositAmount, contractValues);

  const isDisable = !(depositAmount && +depositAmount > 0 && +balance >= +depositAmount);
  const depositStatus = useSelector(
    (state: RootStoreState) => state.ui.TOKEN_GEYSER_DEPOSIT,
  );
  const isLoader = depositStatus === 'REQUEST' ;

  const messageError = useSelector(
    (state: RootStoreState) => state.ui.message,
  );

  useEffect(() => {
    if(depositStatus === 'SUCCESS') {
      clear(); 
    }
  }, [depositStatus]);

  return (
    <>
      <div className={styles.inputWrapper}>
        <P size="sm">{`Wallet LP token balance: ${balance || '0'} (${
          selectOption.label || ''
        })`}</P>
        <InputAmount
          name={nameInput}
          value={depositAmount}
          onChange={setDepositAmount}
        />
      </div>

      <Card className={styles.withCube}>
        <Cube />
        <div>
          <Tooltip
            target={<i className="icon-attention-alt" />}
            content="Estimated rewards assume you have achieved the maximum reward multiplier."
          />
          <P color="gray">Your estimated rewards</P>
          <H5>{`${numeral(monthlyRewards || 0).format('0,0.00')} ${SYMBOL} / month`}</H5>
        </div>
      </Card>
      <a href={selectOption.value} target="blank" className={styles.buttonUniswap}>
        Get LP tokens
      </a>
      {depositStatus === 'ERROR' && (
        <div className={styles.error}>
          {messageError}
        </div>
      )}
      {isLoader && (
        <div className={styles.containerSpinner}>
          <Spinner/>
        </div>
      )}
      <Button
        name="Deposit"
        loading={isLoader}
        disabled={isDisable}
        className={styles.depositButton}
        onClick={depositHandler}
      />
    </>
  );
};

export default Deposit;
