import { fork } from 'redux-saga/effects';
import deposit from './deposit';
import getPairs from './getPairs';
import withdraw from './withdraw';

export default function* tokenGeyser(): Iterable<any> {
  yield fork(deposit);
  yield fork(getPairs);
  yield fork(withdraw);
}
