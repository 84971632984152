import gql from 'graphql-tag';

export const PAIR_QUERY = gql`
 query pair($id: Bytes!) {
 pair(id: $id){
     token0 {
       id
       symbol
       name
       derivedETH
     }
     token1 {
       id
       symbol
       name
       derivedETH
     }
     reserve0
     reserve1
     reserveUSD
     trackedReserveETH
     token0Price
     token1Price
     volumeUSD
     totalSupply
     txCount
 }
}
`;

export const ETH_PRICE = gql`query bundle {bundle(id:1){ethPrice}}`;

