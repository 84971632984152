import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Select } from '../../components';
import OnboardingButton from '../../components/OnboardingButton';
import { P } from '../../components/Typography';
import unicorn from '../../assets/img/uniswap.svg';
import logo from '../../assets/img/logo.svg';
import { SelectOption } from '../../pages/Main';
import { RootStoreState } from '../../types/store';

type Props = {
  selectOption: SelectOption;
  setSelectOption: (arg0: any) => void;
  selectOptions: SelectOption[];
  ethAddress: string | null | undefined;
};

const Header = ({
  selectOption,
  setSelectOption,
  selectOptions,
  ethAddress,
}: Props) => {
  const pairsStatus = useSelector(
    (state: RootStoreState) => state.ui.GET_PAIRS,
  );
  const isLoader = pairsStatus === 'REQUEST' || pairsStatus === 'INIT';

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerControls}>
        <img src={logo} alt="logo" className={styles.logo}/>
        <Select
          loader={isLoader}
          value={selectOption}
          options={selectOptions}
          placeholder={selectOption.label}
          onChange={(e: any) => setSelectOption(e)}
        />
        <a href={selectOption.value}>
          <img src={unicorn} alt="unicorn logo" />
        </a>
      </div>
      {ethAddress ? (
        <P color="gray">
          {`${ethAddress.slice(0, 6)}..${ethAddress.slice(
            ethAddress.length - 4,
          )}`}
        </P>
      ) : (
        <OnboardingButton />
      )}
    </div>
  );
};

export default Header;
