import { takeLeading, call, put, select } from 'redux-saga/effects';
import { getWeb3 } from '../../../utils/metamask';
import actionTypes, { DepositTokenGeyserAction as Action } from '../actionTypes';
import abiTokenGeyser from '../../../appConstants/contracts/abiTokenGeyser.json';
import contractConstants from '../../../appConstants/contractConstants';
import abiPair from '../../../appConstants/contracts/abiPair.json';
import apiActions from '../../api/actions';


function* depositToken({ type, payload }: Action) {
  const { geyser, depositAmount } = payload;
  const web3 = yield call(getWeb3);
  const accounts = yield call(web3.eth.getAccounts);
  const geyserContract = new web3.eth.Contract(abiTokenGeyser, geyser);
  const myAddress = accounts[0];

  const selectedPairs = yield select(state => state.tokenGeyser.selectedPairs);
  const token =  contractConstants[selectedPairs];
  const tokenPair = new web3.eth.Contract(abiPair, token.uniswap);  
  try {
    yield put(apiActions.request(type));

    const balance = yield call(tokenPair.methods
      .balanceOf(myAddress).call, {
      from: myAddress,
      value: 0,
    });

    yield call(tokenPair.methods.approve(
      geyser,
      balance,
    ).send,
    {
      from: myAddress,
    });

    yield call(geyserContract.methods
      .stake(String((+depositAmount * 10 ** 18).toFixed(0)), '0x00').send, {
      from: myAddress,
      value: 0,
    });
    yield put(apiActions.success(type));
    yield put({type: actionTypes.GET_PAIRS});
  } catch (err) {
    yield put(apiActions.error(type, err));
  }
}

export default function* listener(): Iterable<any> {
  yield takeLeading(actionTypes.TOKEN_GEYSER_DEPOSIT, depositToken);
}