import React from 'react';
import styles from './styles.module.scss';
import Routes from '../Routes';

function App() {
  return (
    <div className={styles.app}>
      <Routes />
    </div>
  );
}

export default App;
