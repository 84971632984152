import {TokeGeyserState} from '../../types/store';
import actionTypes, {TokenGeyserAction} from './actionTypes';

const initialState: TokeGeyserState = {
  pairs: [],
  selectedPairs: 0,
  statsData: {
    totalRewards: '',
    totalDeposits: '',
    lockedRewards: '',
    unlockedRewards: '',
  },
  balance: {
    ZCNUSDT: '',
    ZCNWETH: '',
  },
};

export default (state: TokeGeyserState = initialState, action: TokenGeyserAction): TokeGeyserState => {
  switch (action.type) {
    case actionTypes.GET_STATS_DATA_SUCCESS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          statsData: {
            totalRewards: payload.totalRewards,
            totalDeposits: payload.totalDeposits,
            lockedRewards: payload.lockedRewards,
            unlockedRewards: payload.unlockedRewards,
          },
        };
      }
      return {
        ...state,
      };
    }

    case actionTypes.GET_BALANCE_SUCCESS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          balance: action.payload,
        };
      }
      return {
        ...state,
      };
    }

    case actionTypes.SET_SELECTED_PAIRS: {
      const { payload } = action;
      return {
        ...state,
        selectedPairs: payload,
      };
    }

    case actionTypes.GET_PAIRS: {
      return {
        ...state,
        pairs: [],
      };
    }

    case actionTypes.GET_PAIRS_SUCCESS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          pairs: [...state.pairs, action.payload],
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
